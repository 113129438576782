import Installer from "./installer";
import KamiChatEvent from "./event";
import OpenApi from "./openApi";
import Shopify from "./shopify";
import Notification from "./notification";
import PopMessage from "./popMessage";
import ButtonLabel from "./buttonLabel";

declare const window: any;

if (window !== undefined) {
  // PS: 原则上模块之间是没有依赖关系的，如果模块A需要访问模块B中的生成的html，那么模块B要放在A的前面，因为html的渲染都是事情触发的，
  // 事情的订阅具有先后顺序，所以被依赖的模块需要提前渲染
  const installer = new Installer([KamiChatEvent, Notification, Shopify, ButtonLabel, PopMessage]);
  const openApi = new OpenApi(installer);
  window.KamiChat = openApi;
}
