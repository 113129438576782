import { LanguageCode } from "./languageCode";

export enum ChatChannelType {
  WhatsApp = 1,
  Messenger,
  Instagram,
  Telegram,
  WeChat,
  WorkWeChat,
  Line,
  Email,
  Widget,
}

export type ChatChannelItemType = {
  type: ChatChannelType;
  isShow: number | boolean;
  config: string | { areaCode?: string; phone?: string };
};

export type Widget = {
  // 插件配置信息
  // id: string; //
  widgetId: string;
  appId: string; // 应用id
  name: string; // 插件名称
  title: string; // 窗口标题
  location: string; // 位置,left,right
  logo: string; // logo
  bgColor: string; // 背景颜色
  isShowUnread: number; // 是否显示未读消息,0:不显示,1:显示
  showUnreadMessage: number; // 显示未读消息，0：第一条，1：最后一条
  marginBottom: number; // 距离底部距离，单位：像素'
  isFoldSidebar: number; // 侧边栏是否默认收起,0:不收起，1：收起',
  language: LanguageCode; // '语言',
  welcome: string; // 欢迎语

  isShowKamichat: boolean; // 套餐是否为免费版

  chatChannelConfigs: ChatChannelItemType[];

  icon?: string; // 自定义图标
  isShowButtonLabel: 0 | 1; // 最小化时的提示文案
  buttonLabelText?: string;
  isOpenNewMessageTip?: 0 | 1; //  是否打开新消息提示，1：打开，0：关闭",
  isOpenNewMessageSound?: 0 | 1; // 是否打开新消息提示声音，1：打开，0：关闭

  // 聊前调查
  isShowChatSurvey?: 0 | 1; // 是否开启聊前调查，1：开启，0：不开启"
  chatSurveyConfig?: string; // 聊天调查配置
  isShowChatBubble?: 0 | 1; // 是否开启消息气泡提示，1：开启，0：不开启
};

// 挂件模式
export enum WidgetViewMode {
  None,
  Default, // 默认
  Open, // 展开
  WidgetEdit, // 挂机信息编辑模式
  Preview, // 预览模式， 可以正常的发送消息
}

// 挂件的背景颜色
export type WidgetBgColor = {
  bgColorStart: string;
  bgColorEnd: string;
};

export type SurveyField = {
  name: string;
  required: 0 | 1;
};

export type ChatSurveyConfigObj = {
  title?: string;
  items?: SurveyField[];
  // 协议
  privacyStatement?: {
    enabled: 0 | 1;
    message: string;
  };
};
