// 新消息气泡框
import { autobind } from "core-decorators";

import { WidgetViewMode } from "~/common/types/widget";

import { eventName } from "../event/eventName";
import { IInstaller, InstallerCtl } from "../types";
import { KAMI_CHAT_BUTTON_LABTL, WidgetPageMessageType } from "../config";

@autobind
class ButtonLabelCtl extends InstallerCtl {
  constructor(public readonly installer: IInstaller) {
    super(installer);
    this.addMessageListener();
  }

  // 监听消息
  private addMessageListener(): void {
    this.installer.event.on(eventName.POST_MESSAGE, async (msg) => {
      try {
        const { msgType } = msg || {};
        switch (msgType) {
          case WidgetPageMessageType.WidgetViewMode:
          case WidgetPageMessageType.WidgetInfo:
          case WidgetPageMessageType.ReceiveTranslate: {
            this.render();
            break;
          }
        }
      } catch (err: any) {
        console.error(err.toString());
      }
    });
  }

  private getStyle(): string {
    const gap = this.installer?.widgetInfo?.isFoldSidebar ? 0 : 16; // button label 与button之间的距离
    const { width, height, bottom, right = 0, left = 0 } = this.installer?.getWidgetMiniPosition?.() || {};
    const styleArr = [`bottom: ${bottom + height / 2 - 20}px`];
    if (this.installer?.widgetInfo?.location === "left") {
      styleArr.push(`left: ${left + width + gap}px`);
    } else {
      styleArr.push(`right: ${right + width + gap}px`);
    }

    return styleArr.join(";");
  }

  render(): void {
    let buttonLabel = document.getElementById(KAMI_CHAT_BUTTON_LABTL);
    if (!this.installer?.widgetInfo?.isShowButtonLabel || this.installer?.widgetMode === WidgetViewMode.Open) {
      if (buttonLabel) {
        buttonLabel.parentNode?.removeChild(buttonLabel);
      }
      return;
    }

    const wrapper = this.installer?.getWrapper?.();
    if (wrapper) {
      if (!buttonLabel) {
        buttonLabel = document.createElement("div");
        buttonLabel.id = KAMI_CHAT_BUTTON_LABTL;
        buttonLabel.className = KAMI_CHAT_BUTTON_LABTL;
        wrapper.appendChild(buttonLabel);
        buttonLabel.onclick = (): void => {
          // 如果是移动端，而且有悬浮菜单，就不允许点击悬浮球打开聊天窗口，而是打开菜单。
          if (this.installer?.checkMobilePopoverMenuShow?.()) return;
          this.installer?.sendWidgetMsg?.(WidgetPageMessageType.OPENAPI_CHANGE_MODE, WidgetViewMode.Open);
        };
      }
      if (buttonLabel) {
        buttonLabel.innerHTML = this.installer?.widgetInfo?.buttonLabelText || this.installer.i18n["与我们聊天"] || "";

        buttonLabel.setAttribute("style", this.getStyle());
      }
    }
  }
}

export default ButtonLabelCtl;
