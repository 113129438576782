import { TFunction } from "@al/aku-im-plugin-internationalization";

const ua = navigator.userAgent;

export let globalTFunction: TFunction;

export const setGlobalI18nInfo = (value: TFunction): void => {
  globalTFunction = value;
};

export const formatImageUrl = (url: string, params: string): string => {
  if (!url) return "";
  return url.split("?")[0] + `?${params}`;
};

//判断safari
export const isSafari = (): boolean => {
  return /Safari/i.test(ua);
};
// ios
export const getIsIos = (): boolean => {
  return /iPhone|iPad|iPod/i.test(ua);
};

// iPad和iPadOS的判断方式不一样
export const isIPadOS = (): boolean => {
  return isSafari() && !getIsIos() && "ontouchend" in document;
};

export const getIsMobile = (): boolean => {
  if (isIPadOS()) {
    return true;
  }
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
};

export const getIsAndroid = (): boolean => {
  return ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1;
};

// export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
