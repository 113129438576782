// 该模块的主要是向外提供操作挂件的功能
import { WidgetViewMode } from "~/common/types/widget";

import { WidgetPageMessageType } from "./config";
import { eventName } from "./event/eventName";
import { IInstaller, InstallerCtl, ListenerData } from "./types";

class OpenApi extends InstallerCtl {
  constructor(public readonly installer: IInstaller) {
    super(installer);
  }

  // 打开弹框
  public open(): void {
    this.installer.sendWidgetMsg(WidgetPageMessageType.OPENAPI_CHANGE_MODE, WidgetViewMode.Open);
  }

  // 关闭弹框
  public close(): void {
    this.installer.sendWidgetMsg(WidgetPageMessageType.OPENAPI_CHANGE_MODE, WidgetViewMode.Default);
  }

  // 当弹框模式改变时触发
  public onModeChange(fn: (mode: WidgetViewMode) => void): void {
    this.installer.event.one(eventName.OPENAPI_MODE_CHANGE, (data: ListenerData) => {
      if (
        data.msgType === WidgetPageMessageType.WidgetViewMode &&
        [WidgetViewMode.Default, WidgetViewMode.Open].includes(data.content as WidgetViewMode)
      ) {
        fn && fn(data.content as WidgetViewMode);
      }
    });
  }
}

export default OpenApi;
