import { ChatChannelType } from "~/common/types/widget";

export const ChatChannelTypeLabel = {
  [ChatChannelType.WhatsApp]: "WhatsApp",
  [ChatChannelType.Messenger]: "Messenger",
  [ChatChannelType.Instagram]: "Instagram",
  [ChatChannelType.Telegram]: "Telegram",
  [ChatChannelType.WeChat]: "WeChat",
  [ChatChannelType.WorkWeChat]: "WorkWeChat",
  [ChatChannelType.Line]: "Line",
  [ChatChannelType.Email]: "Email",
  [ChatChannelType.Widget]: "Widget",
};
