import md5 from "md5";

import colorUtils from "~/common/utils/colorUtils";
import { Widget, ChatChannelItemType } from "~/common/types/widget";
import { KAMICHAT_WIDGET_CLIENT_ID_PREFIX, KAMICHAT_WIDGET_OPENED } from "~/constants";
import { getAssetsPrefix, getSassDomain } from "~/common/utils/config";

import { chatChannelResolveItemType } from "./types";

export const isKmaichatService = (): boolean => location.host.endsWith(getSassDomain());

const invertedColor = "#0071FF";

// 获取图标的颜色
export const getDefaultMiniColor = (bgColor: string): string => {
  const bgColorArr = colorUtils.extractRgbArr(bgColor);
  if (!bgColorArr) {
    return "#ffffff";
  }
  if (colorUtils.getThreshold(bgColorArr) > 200) {
    return invertedColor;
  }
  return "#ffffff";
};
// 获取shadow的颜色
export const getDefaultMiniFilter = (bgColor: string): string => {
  const bgColorArr = colorUtils.extractRgbArr(bgColor);
  if (!bgColorArr) {
    return `drop-shadow(0px 2px 14px ${bgColor})`;
  }

  if (colorUtils.getThreshold(bgColorArr) > 200) {
    return `drop-shadow(0px 2px 14px ${invertedColor})`;
  }

  return `drop-shadow(0px 2px 14px rgba(${bgColorArr[0]},${bgColorArr[1]},${bgColorArr[2]}, 0.5))`;
};

const resolveItem = (item: ChatChannelItemType): chatChannelResolveItemType => {
  if (item?.config) {
    // JSON.parse 解析错误容易导致页面崩溃，吞掉错误先.
    try {
      return {
        type: item.type,
        ...(typeof item.config === "string" ? JSON.parse(item.config) : item.config),
        isShow: item.isShow,
      };
    } catch {
      return { type: item.type, areaCode: "", isShow: false, phone: "" };
    }
  }
  return { type: item.type, areaCode: "", isShow: false, phone: "" };
};

export const getChatChannelConfig = (widgetInfo?: Widget): void | chatChannelResolveItemType[] => {
  // 这里的 type: 1 是 WhatsApp
  return widgetInfo?.chatChannelConfigs?.filter((item) => item.isShow)?.map(resolveItem);
};

// 获取
const compatibleKmaiChatHost = (host: string): string => {
  if (host.endsWith("www.kamichat.im")) {
    return getSassDomain();
  }

  return host;
};

export const createFingerprint = (options: {
  widgetId?: string;
  isPreview?: boolean;
  source?: number;
  shop?: string;
}): Promise<string> => {
  // @TODO: 这么写是因为写上会出现崩溃问题，还没找到具体的原因
  return new Promise((resolve) => {
    setTimeout(async () => {
      const optionsArr = [
        `widgetId:${options.widgetId}`,
        `shop:${options.shop}`,
        `source:${options.source}`,
        `host:${encodeURIComponent(compatibleKmaiChatHost(location.host))}`,
        `isPreview:${options.isPreview}`,
      ];
      const clientIdStorageKey = `${KAMICHAT_WIDGET_CLIENT_ID_PREFIX}_${optionsArr.join("_")}`;
      const genNewClientId = (): void => {
        // 动态加载指纹的js代码
        const ele = document.createElement("script");
        ele.src = `${getAssetsPrefix()}/static/plugins/fingerprintjs.js`;
        ele.onload = (): void => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          FingerprintJS.load()
            .then((fp: any) => fp.get())
            .then((result: any) => {
              const arr = [result.visitorId, ...optionsArr];
              const clientId = md5(arr.join("__"));
              localStorage.setItem(clientIdStorageKey, clientId);
              resolve(clientId);
            });
        };
        document.body.append(ele);
      };

      try {
        const clientId = localStorage.getItem(clientIdStorageKey) || "";
        if (clientId) {
          resolve(clientId);
          return;
        }
        genNewClientId();
      } catch (err: any) {
        genNewClientId();
        console.error(err.toString());
      }
    });
  });
};

// 是否自定链接ws
export const getAutoLinkWS = (): boolean => {
  const opened = sessionStorage.getItem(KAMICHAT_WIDGET_OPENED);
  if (opened === "true") {
    return true;
  }

  // kamichat 的app站点也自动打开
  return isKmaichatService();
};

export const windowHeight = (): number => {
  let myHeight = 0;
  if (typeof window.innerHeight === "number") {
    //Non-IE
    myHeight = window.innerHeight;
  } else if (document.documentElement && document.documentElement.clientHeight) {
    //IE 6+ in 'standards compliant mode'
    myHeight = document.documentElement.clientHeight;
  } else if (document.body && document.body.clientHeight) {
    //IE 4 compatible
    myHeight = document.body.clientHeight;
  }
  return myHeight;
};

export const uniqBy = <T extends Record<string, unknown>>(arr: T[], identity: string): T[] => {
  if (!Array.isArray(arr)) {
    return arr;
  }
  const result: T[] = [];
  const map: Map<unknown, boolean> = new Map();

  arr.forEach((item: T) => {
    if (Reflect.has(item, identity)) {
      if (!map.has(item[identity])) {
        result.push(item);
        map.set(item[identity], true);
      }
    }
  });

  return result;
};
