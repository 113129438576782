import { autobind } from "core-decorators";

import { ListenerData } from "../types";

type ILisenter = (data: ListenerData) => void;

@autobind
export default class EventListener {
  private eventStock: Map<string, Array<ILisenter>> = new Map();

  /**
   *  添加 支持异步函数
   * @param eventName
   * @param listener
   * @returns
   * PS：事件注册成功后是否返回唯一表示，用来查找和移除事件
   */
  public on(eventName: string, listener: ILisenter): () => void {
    if (typeof listener !== "function") {
      throw new Error("listener is not a function");
    }
    const listeners = this.eventStock.get(eventName) || [];
    listeners.push(listener);
    this.eventStock.set(eventName, listeners);

    return (): void => {
      this.off(eventName, listener);
    };
  }

  // 卸载, listener如果listener没有指定，卸载所有事件
  public off(eventName: string, listener?: ILisenter): void {
    const listeners = this.eventStock.get(eventName) || [];
    if (typeof listener !== "function") {
      this.eventStock.set(eventName, []);
      return;
    }
    this.eventStock.set(
      eventName,
      listeners.filter((item) => item !== listener)
    );
  }

  /**
   * @param eventName
   * @param params
   */
  public dispatch(eventName: string, params: any): void {
    const listeners = this.eventStock.get(eventName) || [];
    for (let i = 0; i < listeners.length; i++) {
      listeners[i](params);
    }
  }

  /**
   *
   * @param eventName : 提供事件名称时清空指定对应名称的事件，否则清空所有事件
   */
  public clear(eventName?: string): void {
    if (eventName) {
      this.eventStock.delete(eventName);
    } else {
      this.eventStock.clear();
    }
  }

  // 只能绑定一个listener, 再次绑定会覆盖前面的事件
  public one(eventName: string, listener: ILisenter): () => void {
    if (typeof listener !== "function") {
      throw new Error("listener is not a function");
    }
    this.eventStock.set(eventName, [listener]);

    return (): void => {
      this.off(eventName, listener);
    };
  }
}
