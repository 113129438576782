// 默认状态 html
export const mini_default_html = `
<div class="widget-mini widget-mini-default">
    <div class="widget-mini-logo">
        <div class="widget-mini-logo-container">
            <div id="widget-logo" class="widget-logo" >
                <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <clipPath id="logoSvgPath">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C1.79086 0 0 1.79086 0 4V16.8851C0 19.0943 1.79086 20.8851 4 20.8851H18.4516L21.5124 23.7324C21.8963 24.0895 22.4908 24.0895 22.8746 23.7324L25.9355 20.8851H28C30.2091 20.8851 32 19.0943 32 16.8851V4C32 1.79086 30.2091 0 28 0H4ZM7.22565 11.6957C8.36585 11.6957 9.29016 10.823 9.29016 9.7464C9.29016 8.66984 8.36585 7.79712 7.22565 7.79712C6.08545 7.79712 5.16113 8.66984 5.16113 9.7464C5.16113 10.823 6.08545 11.6957 7.22565 11.6957ZM18.0646 9.7464C18.0646 10.823 17.1403 11.6957 16.0001 11.6957C14.8599 11.6957 13.9355 10.823 13.9355 9.7464C13.9355 8.66984 14.8599 7.79712 16.0001 7.79712C17.1403 7.79712 18.0646 8.66984 18.0646 9.7464ZM24.774 11.6957C25.9142 11.6957 26.8385 10.823 26.8385 9.7464C26.8385 8.66984 25.9142 7.79712 24.774 7.79712C23.6338 7.79712 22.7095 8.66984 22.7095 9.7464C22.7095 10.823 23.6338 11.6957 24.774 11.6957Z" fill="currentColor"/>
                    </clipPath>
                </svg>
            </div>
        </div>
        <div class="widget-mini-logo-container2">
            <div id="widget-logo2" class="widget-logo2"></div>
        </div>
        <div class="kami-badge widget-unread" style="display: none;"></div>
    </div>
</div>
`;
//  默认状态，带有自定义icon的 html
export const mini_default_custom_icon_html = `
<div class="widget-mini widget-mini-default">
    <div class="widget-mini-logo">
        <div class="widget-mini-logo-container">
            <img id="widget-logo" class="widget-custom-icon" />
        </div>
        <div class="kami-badge widget-unread" style="display: none;"></div>
    </div>
</div>
`;

// 侧边栏html
export const mini_side_html = `
<div class="widget-mini widget-mini-side">
    <div class="widget-mini-logo-side">
        <div class="widget-mini-logo-container">
            <div id="widget-logo" class="widget-logo">
                <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.2669 1.8291H16.2644C9.20424 1.8291 3.48096 7.55255 3.48096 14.6127C3.48096 21.6728 9.20424 27.3963 16.2644 27.3963H35.0503V14.6127C35.0503 7.55255 29.3271 1.8291 22.2669 1.8291ZM31.3144 14.582C31.3144 19.4223 27.3905 23.346 22.5504 23.346H15.9811C11.1408 23.346 7.21706 19.4221 7.21706 14.582C7.21706 9.74166 11.1409 5.81794 15.9811 5.81794H22.5504C27.3905 5.81778 31.3144 9.74166 31.3144 14.582Z" fill="white"/>
                <path d="M23.8733 17.2271C25.3165 17.2271 26.4864 16.0572 26.4864 14.6141C26.4864 13.1709 25.3165 12.001 23.8733 12.001C22.4302 12.001 21.2603 13.1709 21.2603 14.6141C21.2603 16.0572 22.4302 17.2271 23.8733 17.2271Z" fill="white"/>
                <path d="M14.7371 17.2271C16.1803 17.2271 17.3502 16.0572 17.3502 14.6141C17.3502 13.1709 16.1803 12.001 14.7371 12.001C13.2939 12.001 12.124 13.1709 12.124 14.6141C12.124 16.0572 13.2939 17.2271 14.7371 17.2271Z" fill="white"/>
                <path d="M2.71544 10.2604C3.10757 9.02632 3.66337 7.86537 4.35728 6.80264L4.2674 6.72563C4.00196 6.49813 4.18573 5.68091 4.83044 4.9288C5.47499 4.17669 6.2541 3.86929 6.51987 4.09727L6.58675 4.15451C7.5379 3.26847 8.60979 2.51074 9.77589 1.90944C9.66303 1.57647 9.09404 1.09302 9.09404 1.09302C8.74725 0.795748 8.30849 0.632239 7.79015 0.606997C7.30927 0.583363 6.77968 0.68015 6.21584 0.894303C5.08334 1.3239 3.92431 2.18549 2.95274 3.31976C1.98118 4.45387 1.30688 5.73107 1.05575 6.9155C0.963146 7.35185 0.931151 7.7599 0.95993 8.13354C0.969577 8.26184 0.987262 8.38949 1.01299 8.51554C1.11749 9.02391 1.34627 9.43228 1.69307 9.72956C1.69307 9.72956 2.13857 10.1442 2.71544 10.2604Z" fill="white"/>
                </svg>
            </div>
        </div>
        <div class="kami-badge widget-unread" style="display: none;"></div>
    </div>
</div>
`;

export const button_label_html = `
    <div id="kami-chat-button-label" class="kami-chat-button-label"></div>
`
