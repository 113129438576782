export const getLiveChatSDKURL = (): string => {
  const env = process.env.BUILD_ENV as string;
  const defaultReturnUrl: { [key: string]: string } = {
    local: "https://dev-livechat.akulaku.com",
    dev: "https://dev-livechat.akulaku.com",
    test: "https://test-livechat.akulaku.com",
    prod: "https://livechat.akulaku.com",
  };
  return defaultReturnUrl[env];
};

export const isLocal = (): boolean => process.env.BUILD_ENV === "local";

// 获取sass域名
export const getSassDomain = (): string => {
  switch (process.env.BUILD_ENV) {
    case "prod":
      return "app.kamichat.im";
    case "test":
      return "test-app.kamichat.im";
    case "dev":
      return "dev-app.kamichat.im";
    default:
      return "localhost:12001";
  }
};

export const getSassOrigin = (): string => {
  const domain = getSassDomain();
  switch (process.env.BUILD_ENV) {
    case "prod":
    case "test":
    case "dev":
      return `https:${domain}`;
    default:
      return `http:${domain}`;
  }
};

export const getWidgetDomain = (): string => {
  switch (process.env.BUILD_ENV) {
    case "prod":
      return "widget.kamichat.im";
    case "test":
      return "test-widget.kamichat.im";
    case "dev":
      return "dev-widget.kamichat.im";
    default:
      return "localhost:12000";
  }
};

export const getWidgetOrigin = (): string => {
  const domain = getWidgetDomain();
  switch (process.env.BUILD_ENV) {
    case "prod":
    case "test":
    case "dev":
      return `https:${domain}`;
    default:
      return `http:${domain}`;
  }
};

export const getAssetsPrefix = (): string => {
  switch (process.env.BUILD_ENV) {
    case "prod":
      return "https://static.kamichat.im/biz-chat-plugin";
    case "test":
      return "https://test-widget.kamichat.im";
    case "dev":
      return "https://dev-widget.kamichat.im";
    default:
      return "http://localhost:12000";
  }
};
