import { Widget, WidgetViewMode, ChatChannelType } from "~/common/types/widget";

import { WidgetPageMessageType } from "./config";
import EventListener from "./event/eventListener";

export type ListenerData = {
  msgType: string; // 消息类型
  content: unknown; // 消息体
};

export interface IInstaller {
  isEdit?: boolean; // 是否为编辑模式
  isPreview?: boolean; // 是否为预览模式
  kickouted?: boolean;
  oldWidgetInfo?: Widget;
  widgetInfo?: Widget;
  widgetElement?: HTMLIFrameElement;
  popupElement?: HTMLIFrameElement;
  audioElement?: HTMLAudioElement; // 音频
  event: EventListener;
  i18n: Record<string, string>;
  widgetMode?: WidgetViewMode;
  sendWidgetMsg: (msgType: WidgetPageMessageType, content?: unknown) => void;
  sendPopupMsg: (msgType: WidgetPageMessageType, content?: unknown) => void;
  pageSwitch: () => void;
  setWidgetInfo: (widget: Widget) => void; // 设置挂件信息
  rerenderWidget: () => void;
  switchViewMode: (mode: WidgetViewMode) => void;
  setKickout: (kickouted: boolean) => void;
  setUnreadCount: (count: number) => void;
  getSource: () => number;
  setWidgetHeight: () => void;
  getWrapper: () => HTMLDivElement; // 获取 kami-widget-wrapper 元素
  createPopup: () => void; // 创建popup iframe
  getWidgetMiniPosition: () => {
    right?: number;
    bottom: number;
    left?: number;
    width: number;
    height: number;
  };
  checkMobilePopoverMenuShow: () => boolean; // @TODO: 该方法暂时放这里，后面删除，以及对应的逻辑也需做调整
}

export class InstallerCtl {
  // 允许哪些source使用该模块的功能，如果不指定，表示有所source都可以
  static get allowSources(): number[] | undefined {
    return undefined;
  }

  constructor(public readonly installer: IInstaller) {}
}

export type chatChannelResolveItemType = { type: ChatChannelType; areaCode: string; isShow: boolean; phone: string };
