// 新消息气泡框
import { autobind } from "core-decorators";
// import { uniqBy } from "lodash-es";

import { WidgetViewMode } from "~/common/types/widget";

import { eventName } from "../event/eventName";
import { IInstaller, InstallerCtl } from "../types";
import { KAMI_NEW_MSG_LIST_ELE, WidgetPageMessageType } from "../config";

import { PopMessage } from "./types";
import { uniqBy } from "../utils";

@autobind
class PopNewMessageCtl extends InstallerCtl {
  private _msgListEle?: HTMLDivElement;

  private msgList: PopMessage[] = []; // 新消息列表, 有新消息就会显示消息列表，已读后消失

  private csProfileInfo?: { nickname: string; avatar: string };

  constructor(public readonly installer: IInstaller) {
    super(installer);
    this.addMessageListener();
  }

  // 监听消息
  private addMessageListener(): void {
    this.installer.event.on(eventName.POST_MESSAGE, async (msg) => {
      try {
        const { msgType, content } = msg || {};
        switch (msgType) {
          case WidgetPageMessageType.WidgetViewMode: {
            if (content === WidgetViewMode.Open) {
              this.clear();
            } else {
              this.showSimulationData();
            }
            break;
          }
          case WidgetPageMessageType.NewMsgList: {
            this.receiveNewMsgList(content as PopMessage[]);
            break;
          }
          case WidgetPageMessageType.WidgetInfo: {
            this.showSimulationData();
            break;
          }
          case WidgetPageMessageType.WidgetPreviewCSInfo: {
            this.csProfileInfo = content as { nickname: string; avatar: string };
            break;
          }
          case WidgetPageMessageType.ReceiveTranslate: {
            this.renderMsgList();
            break;
          }
        }
      } catch (err: any) {
        console.error(err.toString());
      }
    });
  }

  // 显示模拟数据
  private showSimulationData(): void {
    if (this.installer.isEdit) {
      this.msgList = [];

      if (
        this.installer?.oldWidgetInfo &&
        this.installer.oldWidgetInfo.isShowChatBubble !== this.installer.widgetInfo?.isShowChatBubble
      ) {
        // 如果 isShowChatBubble 是从关闭变为打开状态，那么关闭聊天框
        this.installer.sendWidgetMsg(WidgetPageMessageType.WidgetPreviewMode, WidgetViewMode.Default);
      }

      if (this.installer.widgetInfo?.isShowChatBubble) {
        // 如果是在编辑模式，手动插入一条模式数据
        this.receiveNewMsgList([
          {
            id: Date.now() + "",
            content: this.installer.i18n["你好👋"],
            nickname: this.csProfileInfo?.nickname || "",
          },
        ]);
      } else {
        this.clear();
      }
    }
  }

  private getMsgListEleStyle(): string {
    const styleArr = [];
    if (this._msgListEle) {
      styleArr.push("display:block");
    }
    const widgetPosition = this.installer?.getWidgetMiniPosition?.();
    if (widgetPosition) {
      styleArr.push(`bottom:${widgetPosition.bottom + widgetPosition.height + 8}px`);
      if (this.installer.widgetInfo?.location === "left") {
        styleArr.push(`left: ${widgetPosition.left}px`);
      } else {
        styleArr.push(`right: ${widgetPosition.right}px`);
      }
    }

    return styleArr.join(";");
  }

  private createMsgListEle(): void {
    if (this._msgListEle) {
      return;
    }

    const node = document.createElement("div");
    node.id = KAMI_NEW_MSG_LIST_ELE;
    node.setAttribute("style", this.getMsgListEleStyle());
    const wrapper = this.installer?.getWrapper();
    if (wrapper) {
      wrapper.appendChild(node);
    }
    this._msgListEle = node;
    this._msgListEle.addEventListener(
      "click",
      () => {
        this.installer.sendWidgetMsg(WidgetPageMessageType.OPENAPI_CHANGE_MODE, WidgetViewMode.Open);
      },
      false
    );
  }

  private renderMsgItem(msg: PopMessage, style?: string): string {
    return `
    <div class="kami-msg-item" style="${style}">
      <div class="kami-msg-item-header">📮 ${this.installer.i18n["来自"]}: ${msg.nickname}</div>
      <p class="kami-msg-item-content">${msg.content}</p>
    </div>`;
  }

  // 消息列表
  // 最多显示两条条在视野中，其余的进行滚动
  private renderMsgList(): void {
    this.createMsgListEle();
    if (this._msgListEle && this.msgList.length) {
      // 默认展示堆叠效果
      const needRenderMsgList = this.msgList.reverse();
      const msgItems: string[] = [];
      for (let index = needRenderMsgList.length - 1; index >= 0; index--) {
        const style = `position:absolute;bottom:${index * 16}px;transform:scale(${1 - 0.05 * index});`; // 实现元素的堆叠效果
        msgItems.push(this.renderMsgItem(needRenderMsgList[index], style));
      }
      this._msgListEle.innerHTML = msgItems.join("");

      this._msgListEle.setAttribute("style", this.getMsgListEleStyle());
    }
  }

  private receiveNewMsgList(msgList: PopMessage[]): void {
    if (!this.installer?.widgetInfo?.isShowChatBubble) {
      return;
    }
    // 关闭状态下才显示新消息列表
    if (this.installer.widgetMode === WidgetViewMode.Default) {
      this.msgList = uniqBy([...this.msgList, ...msgList], "id").slice(-2); // 消息去重，并保留最后两条数据
      this.renderMsgList();
    }
  }

  private clear(): void {
    this.msgList = [];
    if (this._msgListEle) {
      this._msgListEle.parentElement?.removeChild(this._msgListEle);
      this._msgListEle = undefined;
    }
  }
}

export default PopNewMessageCtl;
