export const getThreshold = (rgbArr: number[]): number => {
  // return 0.213 * rgbArr[0] + 0.715 * rgbArr[1] + 0.072 * rgbArr[2];
  return 0.299 * rgbArr[0] + 0.587 * rgbArr[1] + 0.114 * rgbArr[2];
};

// 颜色是否查超过规定的阈值
export const isThresholdExceeded = (rgbArr: number[]): boolean => {
  try {
    return getThreshold(rgbArr) > 255 / 2;
  } catch {
    return false;
  }
};

// 通过背景颜色获取文字颜色
export const getFontColorByBg = (rgbArr: number[]): string => {
  try {
    // 当color值大于128时,color值偏向255,即#ffffff,此时字体颜色应为#000000
    // 当color值小于128时,color值偏向0,即#000000,此时字体颜色应为#ffffff
    // 255 / 186
    if (rgbArr) {
      return isThresholdExceeded(rgbArr) ? "#000000" : "#ffffff";
    }
    return "#ffffff";
  } catch {
    return "#ffffff";
  }
};

export const getColorsFormLinearGradient = (data: string): string[] => {
  const regex = /rgba?\(.*?\)|#[0-9a-fA-F]{3,6}/g;
  const result = [];
  let match = regex.exec(data);
  while (match !== null) {
    result.push(match[0]);
    match = regex.exec(data);
  }
  return result;
};

// 提取rgb颜色
export const extractRgbArr = (colorValue: string): number[] | undefined => {
  try {
    // #123456或者rgb(12,34,56)转为rgb数组[12,34,56]
    colorValue = getColorsFormLinearGradient(colorValue)[Symbol.iterator]().next().value;
    // debugger;
    const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
    const that = colorValue;
    if (/^(rgb|RGB)/.test(that)) {
      // 处理rgb转为数组
      const result = that.replace(/(?:\(|\)|rgb|RGB)*/g, "").split(",");
      return result.map((item) => Number(item));
    } else if (reg.test(that)) {
      // 处理十六进制色值
      let sColor = colorValue.toLowerCase();
      if (sColor && reg.test(sColor)) {
        if (sColor.length === 4) {
          let sColorNew = "#";
          for (let i = 1; i < 4; i += 1) {
            sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
          }
          sColor = sColorNew;
        }
        //处理六位的颜色值
        const sColorChange = [];
        for (let i = 1; i < 7; i += 2) {
          sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
        }
        return sColorChange;
      }
    }
    return undefined;
  } catch {
    return undefined;
  }
};

// 将颜色加深
/**
 *
 * @param color : 原始颜色
 * @param level : level 0-5
 */
export const convertToDark = (color: string, level = 0.5): string => {
  const rgbArr = extractRgbArr(color);
  if (rgbArr) {
    for (let i = 0; i < 3; i++) rgbArr[i] = Math.floor(rgbArr[i] * (1 - level));
    return `rgb(${rgbArr[0]},${rgbArr[1]},${rgbArr[2]})`;
  }

  return color;
};
// 颜色深度减弱
export const convertToLight = (color: string, level = 0.5): string => {
  const rgbc = extractRgbArr(color);
  if (rgbc) {
    for (let i = 0; i < 3; i++) rgbc[i] = Math.floor((255 - rgbc[i]) * level + rgbc[i]);
    return `rgb(${rgbc.join(",")})`;
  }
  return color;
};

/**
 * 比较颜色： color是否大于等于target
 * @param color
 * @param target
 */
export const compareColor = (color: string, target: string): boolean => {
  const colorRgbArr = extractRgbArr(color);
  const targetRgbArr = extractRgbArr(target);
  if (!colorRgbArr || !targetRgbArr) {
    return false;
  }

  return colorRgbArr.some((item, index) => item >= targetRgbArr[index]);
};

export default {
  getThreshold,
  extractRgbArr,
  getFontColorByBg,
  isThresholdExceeded,
  convertToDark,
  convertToLight,
  compareColor,
};
