import { autobind } from "core-decorators";

import { Widget, WidgetViewMode, ChatChannelType } from "~/common/types/widget";
import { getIsMobile, getIsIos } from "~/common/utils";
import {
  widget_iframe_height,
  widget_iframe_width,
  widget_mini_iframe_height,
  widget_mini_iframe_width,
  widget_mini_sider_iframe_height,
  widget_mini_sider_iframe_width,
  widget_padding_vertical,
} from "~/constants/widget";
import { extractWidgetBgColor, formatBgColorGradient } from "~/common/utils/widget";
import { KAMICHAT_WIDGET_OPENED } from "~/constants";

import {
  getContentLink,
  KAMI_WIDGET_WRAPPER,
  KAMI_WIDGET,
  KAMI_WIDGET_ID,
  KAMI_WIDGET_SCRIPT,
  WidgetPageMessageType,
  KAMI_WIDGET_POPUP,
  KAMI_WIDGET_POPOVER_MENU,
  getInstallerAddress,
  KAMI_WIDGET_MINI,
} from "./config";
import BrowserUtils from "./browser";
import { IInstaller, InstallerCtl, ListenerData } from "./types";
import EventListener from "./event/eventListener";
import { mini_default_html, mini_side_html } from "./html";
import {
  getDefaultMiniColor,
  getDefaultMiniFilter,
  getChatChannelConfig,
  createFingerprint,
  getAutoLinkWS,
  windowHeight,
} from "./utils";
import { ChatChannelTypeLabel } from "./constant";
import styles from "./style/style.string.scss";
import baseStyles from "./style/base.string.scss";

const isMobile = getIsMobile();
const isIOS = getIsIos();

@autobind
class Installer implements IInstaller {
  readonly id = URL.createObjectURL(new Blob()).substr(-36); // 用于在浏览器中唯一标识一个kima插件，防止在浏览器出现多个插件时消息混乱

  widgetElement?: HTMLIFrameElement;

  widgetMiniElement?: HTMLIFrameElement;

  popupElement?: HTMLIFrameElement;

  popoverMenuElement?: HTMLDivElement;

  clientUserId?: string;

  widgetId?: string;

  source?: number;

  shop?: string; // shopify店铺域名

  widgetMode?: WidgetViewMode;

  public isEdit?: boolean; // 是否为编辑模式

  public isPreview?: boolean; // 是否为预览模式

  public oldWidgetInfo?: Widget; // 插件上一次的数据（只在编辑模式下有值）， 编辑模式用来数据比对

  public widgetInfo?: Widget; // 插件信息

  public kickouted?: boolean; // 是否被踢出

  private isShowPopoverMenu = false; // 是否显示菜单

  i18n: Record<string, string> = {};

  listeners: Array<(data: ListenerData) => void> = [];

  event = new EventListener();

  constructor(readonly ctls: Array<typeof InstallerCtl>) {
    this.getWidgetInfo(); // 先获取挂件信息的原因是不同的平台添加的功能模块不同

    for (const Ctl of ctls) {
      const allowSources = Ctl.allowSources;
      if (Array.isArray(allowSources) && allowSources.length) {
        if (this.source && allowSources.includes(this.source)) {
          new Ctl(this);
        }
      } else {
        new Ctl(this);
      }
    }
    this.initialize();
  }

  private async initialize(): Promise<void> {
    try {
      const clientUserId = await createFingerprint({
        widgetId: this.widgetId,
        isPreview: this.isPreview,
        source: this.source,
        shop: this.shop,
      });
      this.clientUserId = clientUserId;
      setTimeout(this.createIframe);
    } catch (err: any) {
      console.error(err);
    }
  }

  // 获取挂件信息
  private getWidgetInfo(): void {
    const kami_script = this.getkamiChatScript();
    if (kami_script) {
      //获取 widget id

      this.widgetId = this.extracteWidgetId(kami_script);
      this.source = this.extracteSource(kami_script);
      this.shop = this.extracteShop(kami_script);
      this.widgetMode = this.extracteMode(kami_script);
      this.isEdit = this.extractIsEdit(kami_script);
      this.isPreview = this.extractIsPreview(kami_script);
    }
  }

  public setWidgetInfo(widgetInfo: Widget): void {
    if (this.isEdit) {
      // 编辑用来数据比对
      this.oldWidgetInfo = this.widgetInfo;
    }
    // FIXME 因为小图标模式的动画不佳，暂时隐藏，并强制转换小图标为悬浮球
    this.widgetInfo = { ...widgetInfo, isFoldSidebar: 0 };
  }

  private extracteWidgetId(kamiScript: HTMLScriptElement): string | undefined {
    const widgetId = (kamiScript.getAttribute("wid") as string) || "";
    if (widgetId) {
      return widgetId;
    }
    const srcUrlSearchParams = new URL(kamiScript.src).searchParams;
    return (srcUrlSearchParams.get("wid") as string) || "";
  }

  private extracteSource(kamiScript: HTMLScriptElement): number | undefined {
    const source =
      kamiScript.getAttribute("source") && !isNaN(Number(kamiScript.getAttribute("source")))
        ? Number(kamiScript.getAttribute("source"))
        : undefined;
    if (source) {
      return source;
    }
    const srcUrlSearchParams = new URL(kamiScript.src).searchParams;
    return srcUrlSearchParams.get("source") && !isNaN(Number(srcUrlSearchParams.get("source")))
      ? Number(srcUrlSearchParams.get("source"))
      : undefined;
  }

  private extracteShop(kamiScript: HTMLScriptElement): string | undefined {
    const shop = (kamiScript.getAttribute("shop") as string) || "";
    if (shop) {
      return shop;
    }
    const srcUrlSearchParams = new URL(kamiScript.src).searchParams;
    return (srcUrlSearchParams.get("shop") as string) || "";
  }

  private extracteMode(kamiScript: HTMLScriptElement): number | undefined {
    const _mode = kamiScript.getAttribute("mode");
    const mode = _mode ? parseInt(_mode) : undefined;
    if (mode) {
      return mode;
    }
    const srcUrlSearchParams = new URL(kamiScript.src).searchParams;
    const urlMode = srcUrlSearchParams.get("mode");
    if (urlMode) {
      return parseInt(urlMode);
    }
    return undefined;
  }

  private extractIsEdit(kamiScript: HTMLScriptElement): boolean {
    const isEdit = (kamiScript.getAttribute("isEdit") as string) || "";
    if (isEdit === "true") {
      return true;
    }
    const srcUrlSearchParams = new URL(kamiScript.src).searchParams;
    return ((srcUrlSearchParams.get("isEdit") as string) || "") === "true";
  }

  private extractIsPreview(kamiScript: HTMLScriptElement): boolean {
    const isPreview = (kamiScript.getAttribute("isPreview") as string) || "";
    if (isPreview === "true") {
      return true;
    }
    const srcUrlSearchParams = new URL(kamiScript.src).searchParams;
    return ((srcUrlSearchParams.get("isPreview") as string) || "") === "true";
  }

  private getkamiChatScript(): HTMLScriptElement | undefined {
    const kami_script = document.getElementById(KAMI_WIDGET_SCRIPT) as HTMLScriptElement;
    if (kami_script) {
      return kami_script;
    }
    // shopify网页上没有id属性，所以遍历所有的script进行匹配
    const installerAddress = getInstallerAddress();
    const scripts = document.scripts;
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].src.startsWith(installerAddress)) {
        return scripts[i];
      }
    }
    return undefined;
  }

  private createIframe(): void {
    // wrapper
    this.createWrapper();
    this.createWidgetMini();
    this.createWidget();

    this.widgetElement && this.setIframeSrc(this.widgetElement, KAMI_WIDGET);
  }

  getPosition(): string {
    return "fixed";
  }

  createWidget(): void {
    // widget
    const widgetElement = document.createElement("iframe");
    widgetElement.title = KAMI_WIDGET;
    widgetElement.id = KAMI_WIDGET;
    widgetElement.setAttribute("frameBorder", "0");
    // ios safari height 满屏，参考 https://juejin.cn/post/7096050514105729061
    widgetElement.setAttribute("style", this.getWidgetStyle());
    this.widgetElement = widgetElement;
    if (this.widgetElement) {
      const wrapper = this.getWrapper();
      wrapper.append(this.widgetElement);
    }
  }

  createWidgetMini(): void {
    // widget
    const widgetMIniElement = document.createElement("iframe");
    widgetMIniElement.title = KAMI_WIDGET_MINI;
    widgetMIniElement.id = KAMI_WIDGET_MINI;
    widgetMIniElement.setAttribute("style", this.getWidgetMiniStyle());

    if (widgetMIniElement) {
      const wrapper = this.getWrapper();
      wrapper.appendChild(widgetMIniElement);
    }

    setTimeout(() => {
      // 使用setTimeout 修改firefox下iframe不显示的问题 https://cloud.tencent.com/developer/ask/sof/201329
      const doc = widgetMIniElement.contentDocument;
      if (doc) {
        //style
        const style = doc.createElement("style");
        style.innerHTML = styles;
        doc.head.appendChild(style);
      }
    });
    this.widgetMiniElement = widgetMIniElement;
  }

  checkMobilePopoverMenuShow(): boolean {
    if (
      isMobile &&
      this.widgetInfo &&
      this.widgetInfo.chatChannelConfigs &&
      this.widgetInfo?.chatChannelConfigs?.filter((item) => item.isShow).length > 0 &&
      this.popoverMenuElement &&
      window.getComputedStyle(this.popoverMenuElement).display === "none"
    ) {
      this.popoverMenuElement.style.display = "block";
      this.popoverMenuElement.classList.add("kami-widget-popover-menu-active");
      return true;
    }
    if (this.isShowPopoverMenu && this.popoverMenuElement?.style.display === "block") {
      this.popoverMenuElement.style.display = "none";
      this.popoverMenuElement.classList.remove("kami-widget-popover-menu-active");
      // 移动端不允许走到下一步打开窗口。
      if (isMobile) return true;
    }
    return false;
  }

  renderWidgetMiniContent(): void {
    const doc = this.widgetMiniElement?.contentDocument;
    if (!doc) {
      return;
    }
    if (this.widgetInfo?.isFoldSidebar) {
      doc.body.innerHTML = mini_side_html;
    } else {
      const bgColorList = extractWidgetBgColor(this.widgetInfo?.bgColor);
      const bgColorStart = bgColorList[Symbol.iterator]().next().value;

      doc.body.innerHTML = mini_default_html;
      const logoContainer = doc.querySelector(".widget-mini-logo-container") as HTMLDivElement;
      if (logoContainer) {
        logoContainer.style.background = formatBgColorGradient(bgColorList);
      }
      const widgetSvgContainer = doc.getElementById("widget-logo");
      if (widgetSvgContainer) {
        widgetSvgContainer.style.backgroundColor = getDefaultMiniColor(bgColorStart);
      }
      const logoContainer2 = doc.querySelector(".widget-mini-logo-container2") as HTMLDivElement;
      if (logoContainer2) {
        logoContainer2.style.backgroundColor = getDefaultMiniColor(bgColorStart);
      }
      const widgetSvgContainer2 = doc.getElementById("widget-logo2");
      if (widgetSvgContainer2) {
        widgetSvgContainer2.style.background = formatBgColorGradient(bgColorList);
      }
      // }
    }
    const logo = doc.querySelector(".widget-mini-logo-container") as HTMLDivElement;
    if (logo) {
      logo.onclick = (): void => {
        // 如果是移动端，而且有悬浮菜单，就不允许点击悬浮球打开聊天窗口，而是打开菜单。
        if (this.checkMobilePopoverMenuShow()) return;

        this.sendWidgetMsg(
          WidgetPageMessageType.OPENAPI_CHANGE_MODE,
          this.widgetMode === WidgetViewMode.Default ? WidgetViewMode.Open : WidgetViewMode.Default
        );
      };
    }

    const logoWrapper = doc.querySelector(".widget-mini-logo") as HTMLDivElement;
    if (logoWrapper && !isMobile) {
      !logoWrapper.classList.contains("widget-mini-desktop") && logoWrapper.classList.add("widget-mini-desktop");
    }

    const widgetMini = doc.querySelector(".widget-mini");
    if (widgetMini) {
      if (this.widgetInfo?.location === "left") {
        widgetMini.classList.add("widget-mini-left");
      } else {
        widgetMini.classList.remove("widget-mini-left");
      }
    }
  }

  // 在挂件准备完毕后再创建popup，提高首屏的加载效率
  public createPopup(): void {
    // popup
    if (document.getElementById(KAMI_WIDGET_POPUP)) {
      return;
    }
    const popupElement = document.createElement("iframe");
    popupElement.title = KAMI_WIDGET_POPUP;
    popupElement.id = KAMI_WIDGET_POPUP;
    popupElement.setAttribute("frameBorder", "0");
    popupElement.setAttribute(
      "style",
      "max-width:100vw;max-height:100vh;position:fixed;z-index:2147483003;display:none;width:100%;height:100%;top:0;right:0;bottom:0;left:0;"
    );
    this.popupElement = popupElement;
    if (this.popupElement) {
      const wrapper = this.getWrapper();
      wrapper.append(this.popupElement);
      const searchParams: unknown[] = [
        `${KAMI_WIDGET_ID}=${this.widgetId}`,
        `ifId=${KAMI_WIDGET_POPUP}`,
        `instId=${this.id}`,
      ];
      if (this.popupElement) {
        this.popupElement.src = `${getContentLink()}popup.html?${searchParams.join("&")}`;
      }
    }
  }

  createPopoverMenu(): void {
    // 避免重复创建
    if (this.popoverMenuElement) {
      this.popoverMenuElement.remove();
    }
    // popup
    const popoverMenuElement = document.createElement("div");
    popoverMenuElement.title = KAMI_WIDGET_POPOVER_MENU;
    popoverMenuElement.id = KAMI_WIDGET_POPOVER_MENU;
    popoverMenuElement.setAttribute("style", `position:${this.getPosition()}`);
    this.popoverMenuElement = popoverMenuElement;
    if (this.popoverMenuElement) {
      const wrapper = this.getWrapper();
      wrapper.append(this.popoverMenuElement);
    }

    // 隐藏菜单
    const hidePopoverMenu = (): void => {
      popoverMenuElement.style.display = "none";
      popoverMenuElement.classList.remove("kami-widget-popover-menu-active");
    };

    // 处理 hover 出现菜单的逻辑.
    const initOverMenuEvent = (): void => {
      let l: null | NodeJS.Timeout = null;
      const widgetMIniElement = this.widgetMiniElement;
      const widgetMouseEnterHandler = (): void => {
        // 聊天窗口已经打开了的话，悬浮菜单不显示。
        if (
          (typeof this.widgetMode === "number" && WidgetViewMode.Open === this.widgetMode) ||
          !getChatChannelConfig(this.widgetInfo)?.length
        ) {
          return;
        }
        if (l) {
          clearTimeout(l);
          l = null;
        }
        const popoverMenuElement = this.popoverMenuElement;
        if (!popoverMenuElement) return;
        popoverMenuElement.style.display = "block";
        popoverMenuElement.classList.add("kami-widget-popover-menu-active");
      };
      const popoverMenuMouseEnterHandler = (): void => {
        if (l) {
          clearTimeout(l);
          l = null;
        }
      };
      const hideMenu = (): void => {
        l = setTimeout(() => {
          const popoverMenuElement = this.popoverMenuElement;
          if (!popoverMenuElement) return;
          hidePopoverMenu();
          l && clearTimeout(l);
          l = null;
        }, 150);
      };
      // 先移除事件，避免重复绑定
      // 鼠标进入事件
      widgetMIniElement?.removeEventListener("mouseenter", widgetMouseEnterHandler);
      // 鼠标进入事件
      popoverMenuElement.removeEventListener("mouseenter", popoverMenuMouseEnterHandler);
      // 鼠标离开事件
      widgetMIniElement?.removeEventListener("mouseleave", hideMenu);
      // 鼠标离开事件
      popoverMenuElement.removeEventListener("mouseleave", hideMenu);

      // 鼠标进入事件
      widgetMIniElement?.addEventListener("mouseenter", widgetMouseEnterHandler, { passive: false });
      // 鼠标进入事件
      popoverMenuElement.addEventListener("mouseenter", popoverMenuMouseEnterHandler, { passive: false });
      // 鼠标离开事件
      widgetMIniElement?.addEventListener("mouseleave", hideMenu, { passive: false });
      // 鼠标离开事件
      popoverMenuElement.addEventListener("mouseleave", hideMenu, { passive: false });
    };

    // 初始化鼠标悬浮事件
    !isMobile && initOverMenuEvent();

    const popoverMenu = document.createElement("div");
    popoverMenu.className = "widget-popover-menu";
    getChatChannelConfig(this.widgetInfo)?.forEach((item) => {
      const label = ChatChannelTypeLabel[item.type];
      const channelMenu = document.createElement("div");
      channelMenu.className = "widget-popover-menu-item";
      channelMenu.innerHTML = `<i class='widget-popover-menu-item-icon icon-${label?.toLocaleLowerCase()}'></i>${label}`;
      popoverMenu.append(channelMenu);

      const whatsappMenuClickHandler = (): void => {
        // 这里的 type: 1 是 WhatsApp
        const { areaCode, phone, type } = item || {};
        const phoneNum = `${areaCode?.split(":")?.[1] ?? ""}${phone ?? ""}`;
        // 编辑插件预览的时候，在 iframe 里面，location.href 是空的，就不取值了。

        switch (type) {
          case ChatChannelType.WhatsApp:
            phoneNum &&
              window.open(
                `https://api.whatsapp.com/send/?phone=${phoneNum}&text=${
                  location.href || ""
                }&type=phone_number&app_absent=0`,
                "_blank"
              );
            break;
          case ChatChannelType.Messenger:
            phoneNum && window.open(`https://www.messenger.com/t/${phoneNum}/`, "_blank");
            break;
          case ChatChannelType.Instagram:
            phoneNum && window.open(`https://www.instagram.com/${phoneNum}/`, "_blank");
            break;
        }

        hidePopoverMenu();
      };

      // 打开 WhatsApp web 链接
      channelMenu.removeEventListener("click", whatsappMenuClickHandler);
      // 打开 WhatsApp web 链接
      channelMenu.addEventListener("click", whatsappMenuClickHandler);
    });
    if (isMobile) {
      const starChatMenu = document.createElement("div");
      starChatMenu.className = "widget-popover-menu-item";
      starChatMenu.innerHTML = `<i class='widget-popover-menu-item-icon icon-kamichat'></i>${
        this.i18n["开始对话"] || "开始对话"
      }`;
      popoverMenu.append(starChatMenu);
      const starChatMenuClickHandler = (): void => {
        hidePopoverMenu();
        this.sendWidgetMsg(WidgetPageMessageType.OPENAPI_CHANGE_MODE, WidgetViewMode.Open);
      };
      // 先移除事件
      // 打开聊天窗口
      starChatMenu.removeEventListener("click", starChatMenuClickHandler);

      // 打开聊天窗口
      starChatMenu.addEventListener("click", starChatMenuClickHandler);
    }
    popoverMenuElement.appendChild(popoverMenu);
  }

  // 创建wrapper元素
  createWrapper(): void {
    let wrapper = document.getElementById(KAMI_WIDGET_WRAPPER);

    //style
    const style = document.createElement("style");
    style.innerHTML = baseStyles;
    document.head.appendChild(style);
    if (wrapper) {
      wrapper.innerHTML = "";
    } else {
      wrapper = document.createElement("div");
      wrapper.id = KAMI_WIDGET_WRAPPER;
      const body = document.getElementsByTagName("body")[0];
      if (body) {
        body.append(wrapper);
      }
    }
  }

  getWrapper(): HTMLDivElement {
    const wrapper = document.getElementById(KAMI_WIDGET_WRAPPER);
    if (wrapper) {
      return wrapper as HTMLDivElement;
    }
    this.createWrapper();
    return document.getElementById(KAMI_WIDGET_WRAPPER) as HTMLDivElement;
  }

  private setIframeSrc(widgetElement: HTMLIFrameElement, ifId: string): void {
    const searchParams: unknown[] = [
      `${KAMI_WIDGET_ID}=${this.widgetId}`,
      `ifId=${ifId}`,
      `instId=${this.id}`,
      `purl=${encodeURIComponent(location.href)}`,
    ];
    if (this.source) {
      searchParams.push(`source=${this.source}`);
    }
    if (this.shop) {
      searchParams.push(`shop=${this.shop}`);
    }
    if (this.isEdit) {
      searchParams.push(`isEdit=${this.isEdit}`);
    }
    if (this.isPreview) {
      searchParams.push(`isPreview=${this.isPreview}`);
    }
    if (this.clientUserId) {
      searchParams.push(`clientUserId=${this.clientUserId}`);
    }
    searchParams.push(`autoLink=${getAutoLinkWS()}`);

    if (widgetElement) {
      widgetElement.src = `${getContentLink()}?${searchParams.join("&")}`;
    }
  }

  public rerenderWidget(): void {
    if (this.widgetInfo && this.widgetElement && this.widgetMiniElement) {
      const channelConfigs = this.widgetInfo?.chatChannelConfigs;
      // 是否显示悬浮菜单
      if (channelConfigs && channelConfigs.length > 0 && channelConfigs.some((item) => item.isShow)) {
        this.isShowPopoverMenu = true;
        // 鼠标悬浮菜单。
        this.createPopoverMenu();
      } else {
        this.isShowPopoverMenu = false;
      }

      this.widgetMiniElement.setAttribute("style", this.getWidgetMiniStyle());
      this.widgetElement.setAttribute("style", this.getWidgetStyle());

      if (this.popoverMenuElement) {
        const popoverMenuEl = this.popoverMenuElement.querySelector(".widget-popover-menu");
        if (this.widgetInfo.location === "left") {
          this.popoverMenuElement.style.right = "unset";
          this.popoverMenuElement.style.left = "10px";
          if (!popoverMenuEl?.classList.contains("position-left")) {
            popoverMenuEl?.classList.add("position-left");
          }
        }
        popoverMenuEl?.classList[this.widgetInfo?.isFoldSidebar ? "add" : "remove"]("mini");
        if (!this.widgetInfo?.isFoldSidebar) {
          this.popoverMenuElement.style[this.widgetInfo.location === "left" ? "left" : "right"] = isMobile
            ? "10px"
            : "20px";
        }

        this.popoverMenuElement.style.bottom = `${
          (this.widgetInfo?.marginBottom ?? 0) +
          (this.widgetInfo?.isFoldSidebar ? widget_mini_sider_iframe_height : widget_mini_iframe_height) +
          52
        }px`;
      }
    }

    this.renderWidgetMiniContent();
  }

  // 切换挂件的视图模式
  public switchViewMode(viewMode: WidgetViewMode): void {
    if (!this.widgetElement) {
      return;
    }
    const newViewMode = +viewMode;
    switch (newViewMode) {
      case WidgetViewMode.Default: {
        if (this.widgetElement) {
          this.widgetElement.classList.remove("kami-widget-enter-active");
          document.body.classList.remove("kami-body-disabled");
        }
        if (this.widgetMiniElement) {
          // this.widgetMiniElement.style.opacity = "1";
          this.widgetMiniElement.classList.remove("kami-widget-minimized-un-active");
        }
        break;
      }
      case WidgetViewMode.Open:
        if (this.widgetElement) {
          this.widgetElement.classList.add("kami-widget-enter-active");
        }
        if (this.widgetMiniElement) {
          this.widgetMiniElement.classList.add("kami-widget-minimized-un-active");
        }
        if (isMobile) {
          document.body.classList.add("kami-body-disabled");
        }
        // 记录用户是否打开过聊天框，刷新页面后自动打开
        sessionStorage.setItem(KAMICHAT_WIDGET_OPENED, "true");
        break;
    }
    this.widgetMode = newViewMode;
  }

  public sendWidgetMsg(msgType: WidgetPageMessageType, content: unknown): void {
    this.widgetElement?.contentWindow?.postMessage(
      {
        msgType,
        instId: this.id,
        content,
      },
      "*"
    );
  }

  public sendPopupMsg(msgType: WidgetPageMessageType, content: unknown): void {
    this.popupElement?.contentWindow?.postMessage(
      {
        msgType,
        instId: this.id,
        content,
      },
      "*"
    );
  }

  // web页面切换监听
  public pageSwitch(): void {
    if (!document[BrowserUtils.getHiddenName()] && this.kickouted) {
      // 如果当前状态不是ready状态，同时没有在登录中，就登陆一次
      // this.widgetElement = undefined;
      // this.popupElement = undefined;
      // this.widgetId = undefined;
      // this.widgetMode = undefined;
      // this.widgetInfo = undefined;
      // this.setKickout(false);
      // this.initialize();
    }
  }

  public setKickout(kickouted: boolean): void {
    this.kickouted = kickouted;
  }

  // 设置mini上的未读数
  public setUnreadCount(count: number): void {
    const doc = this.widgetMiniElement?.contentDocument;
    if (doc) {
      const widgetMini = doc.querySelector(".kami-badge") as HTMLDivElement;
      if (widgetMini) {
        const str = count >= 99 ? "99+" : `${count}`;
        widgetMini.textContent = count ? str : "";
        widgetMini.style.display = count ? "flex" : "none";
      }
    }
  }

  public getSource(): number {
    return this.source as number;
  }

  private getWidgetHeight(): number | undefined {
    let realHeight = widget_iframe_height;
    if (this.widgetElement) {
      const height = widget_iframe_height + widget_padding_vertical + (this.widgetInfo?.marginBottom || 0);
      const winHeight = windowHeight();
      if (height > winHeight) {
        realHeight = widget_iframe_height - (height - winHeight);
      }
      return Math.max(realHeight, 350);
    }
  }

  // 设置聊天框高度
  public setWidgetHeight(): void {
    if (isMobile) return;
    if (this.widgetElement) {
      const height = this.getWidgetHeight();
      if (typeof height !== "undefined") {
        this.widgetElement.style.height = height + "px";
      }
    }
  }

  private getWidgetStyle(): string {
    const styleArr: string[] = [];

    // width
    styleArr.push(`width:${isMobile ? "100vw" : `${widget_iframe_width}px`}`);
    // outline
    styleArr.push("outline:none");
    //box-shadow
    styleArr.push("box-shadow:none");
    // height
    if (isMobile) {
      styleArr.push(`height:${isIOS ? "100vh;height:-webkit-fill-available" : "100vh"}`);
    } else {
      styleArr.push(`height:${this.getWidgetHeight() || widget_iframe_height}px`);
    }

    // position
    styleArr.push(`position:${this.getPosition()}`);
    // position === left
    if (this.widgetInfo?.location === "left") {
      // left
      styleArr.push(`left:0`);
      // right
      styleArr.push(`right: ${isMobile ? "0" : "unset"}`);
    } else {
      // left
      styleArr.push(`right:0`);
      // right
      styleArr.push(`left: ${isMobile ? "0" : "unset"}`);
    }
    // bottom
    styleArr.push(`bottom: ${isMobile ? "0" : (this.widgetInfo?.marginBottom ?? 0) + "px"}`);

    return styleArr.join(";");
  }

  private getWidgetMiniStyle(): string {
    const styleArr: string[] = [];
    // position
    styleArr.push(`position:${this.getPosition()}`);

    if (this.widgetInfo?.location === "left") {
      // left
      styleArr.push(`left:${this.widgetInfo?.isFoldSidebar ? "0" : isMobile ? "10px" : "22px"}`);
      // right
      styleArr.push(`right:unset`);
      // this.widgetElement.style.transformOrigin = "left bottom";
    } else {
      // left
      styleArr.push(`left:unset`);
      // 移动端固定右侧距离
      // right
      styleArr.push(`right:${this.widgetInfo?.isFoldSidebar ? "0" : isMobile ? "10px" : "22px"}`);
      // this.widgetElement.style.transformOrigin = "right bottom";
    }

    if (this.widgetInfo?.isFoldSidebar) {
      styleArr.push(`width:${widget_mini_sider_iframe_width}px`);
      styleArr.push(`height:${widget_mini_sider_iframe_height}px`);
      styleArr.push(`height:${widget_mini_sider_iframe_height}px`);
    } else {
      styleArr.push(`width:${widget_mini_iframe_width}px`);
      styleArr.push(`height:${widget_mini_iframe_height}px`);

      const bgColorList = extractWidgetBgColor(this.widgetInfo?.bgColor);
      const bgColorStart = bgColorList[Symbol.iterator]().next().value;
      styleArr.push(`filter:${getDefaultMiniFilter(bgColorStart)}`);
    }

    if (this.widgetMode === WidgetViewMode.Default) {
      styleArr.push(`opacity:1`);
    } else {
      styleArr.push(`opacity:0`);
    }
    // 移动端固定底部距离
    // styleArr.push(
    //   `bottom:${isMobile ? "30px" : (this.widgetInfo?.marginBottom ?? 0) + widget_padding_vertical + "px"}`
    // );
    styleArr.push(`bottom:${(this.widgetInfo?.marginBottom ?? 0) + widget_padding_vertical + "px"}`);

    return styleArr.join(";");
  }

  // 获取挂件最小化的定位数据
  public getWidgetMiniPosition(): {
    right?: number;
    bottom: number;
    left?: number;
    width: number;
    height: number;
  } {
    const horizontalDistance = isMobile ? 10 : 22;
    const positionInfo: {
      right?: number; // 右边距离屏幕右边的位置
      bottom: number; // 挂件底部距离屏幕底部的距离
      left?: number; // 左边距离屏幕左边的位置
      width: number; // 挂件的宽度
      height: number; // 挂件的高度
    } = {
      bottom: (this.widgetInfo?.marginBottom ?? 0) + widget_padding_vertical,
      width: widget_mini_iframe_width,
      height: widget_mini_iframe_height,
    };
    if (this.widgetInfo?.location === "left") {
      positionInfo.left = horizontalDistance;
    } else {
      positionInfo.right = horizontalDistance;
    }

    return positionInfo;
  }
}

export default Installer;
