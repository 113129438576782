import { WidgetBgColor } from "../types/widget";

export const extractWidgetBgColor = (bgColor?: string): string[] => {
  if (!bgColor) {
    return [];
  }

  try {
    const result = JSON.parse(bgColor);
    if (typeof result === "object") {
      const bgColorObj = result as WidgetBgColor;
      return [bgColorObj.bgColorStart, bgColorObj.bgColorEnd];
    }
    return [bgColor, bgColor];
  } catch {
    return [bgColor, bgColor];
  }
};

// 获取背景色的渐变
export const formatBgColorGradient = (bgColorObj: string[]): string =>
  `linear-gradient(115deg, ${bgColorObj?.[0]}, ${bgColorObj?.[1]})`;
