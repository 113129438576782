// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class BrowserUtils {
  // 获取visibilitychange属性名
  static getVisibilityChangeEventName(): string {
    let visibilityChange = "";
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      visibilityChange = "visibilitychange";
      // @ts-ignore
    } else if (typeof document?.msHidden !== "undefined") {
      visibilityChange = "msvisibilitychange";
      // @ts-ignore
    } else if (typeof document?.webkitHidden !== "undefined") {
      visibilityChange = "webkitvisibilitychange";
    }

    return visibilityChange;
  }

  // 获取hidden属性名
  static getHiddenName(): keyof Document {
    let hidden = "";
    if (typeof document.hidden !== "undefined") {
      // Opera 12.10 and Firefox 18 and later support
      hidden = "hidden";
      // @ts-ignore
    } else if (typeof document?.msHidden !== "undefined") {
      hidden = "msHidden";
      // @ts-ignore
    } else if (typeof document?.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
    }

    return hidden as keyof Document;
  }

  static isHidden(): boolean {
    return document[BrowserUtils.getHiddenName()] as boolean;
  }
}
