import { autobind } from "core-decorators";
// import { debounce } from "lodash-es";

import { PostMessage } from "~/common/types/postMessage";
import { Widget, WidgetViewMode } from "~/common/types/widget";
import { Noop } from "~/common/types";

import BrowserUtils from "../browser";
import { WidgetPageMessageType } from "../config";
import { MessageNotice } from "../messageNotice";
import { IInstaller, InstallerCtl } from "../types";

import { eventName } from "./eventName";

declare const window: any;

let timer: NodeJS.Timeout;

const debounce =
  <T extends Noop>(callback: T, wait: number) =>
  (): void => {
    clearTimeout(timer);
    timer = setTimeout(callback, wait);
  };

@autobind
export default class KamiChatEvent extends InstallerCtl {
  messageNotice?: MessageNotice;

  constructor(public readonly installer: IInstaller) {
    super(installer);

    this.addMessageListener();
    this.addPostMessageListener();
    this.addDocumentListener();
    this.pageChangeListener();
  }

  // 监听消息
  private addMessageListener(): void {
    this.installer.event.on(eventName.POST_MESSAGE, (msg) => {
      const { msgType, content } = msg || {};
      switch (msgType) {
        case WidgetPageMessageType.WidgetInfo:
          this.installer.setWidgetInfo(content as Widget);
          this.installer.rerenderWidget();
          break;
        case WidgetPageMessageType.ReceiveTranslate:
          this.installer.i18n = content as Record<string, string>;
          this.installer.rerenderWidget();
          break;
        case WidgetPageMessageType.WidgetViewMode:
          this.installer.switchViewMode(content as WidgetViewMode);
          this.installer.rerenderWidget();
          this.installer.event.dispatch(eventName.OPENAPI_MODE_CHANGE, msg);
          break;
        case WidgetPageMessageType.ViewImage: {
          if (this.installer.popupElement) {
            this.installer.popupElement.style.display = "block";
            this.installer.sendPopupMsg(WidgetPageMessageType.ViewImage, content);
          }
          break;
        }
        case WidgetPageMessageType.ViewImageClose: {
          if (this.installer.popupElement) {
            this.installer.popupElement.style.display = "none";
          }
          break;
        }
        case WidgetPageMessageType.WidgetKickout: {
          this.installer.setKickout(true);
          break;
        }
        case WidgetPageMessageType.PostFirstTrack: {
          // ws链接成功后，上报一次数据
          this.sendPageInfo();
          break;
        }
        case WidgetPageMessageType.WidgetReady: {
          // 每次初始化成功都需要重置kickout状态
          this.installer.setKickout(false);
          // 挂件准备好后再加载popup
          this.installer.createPopup();
        }
      }

      if (this.installer.isEdit) {
        switch (msgType) {
          case WidgetPageMessageType.WidgetPreviewData:
            // this.installer.setWidgetInfo(content as Widget);
            // this.installer.rerenderWidget();
            this.installer.sendWidgetMsg(WidgetPageMessageType.WidgetPreviewData, content);
            break;
          case WidgetPageMessageType.WidgetPreviewMode:
            this.installer.sendWidgetMsg(WidgetPageMessageType.WidgetPreviewMode, content);
            break;
          case WidgetPageMessageType.WidgetPreviewShowChatSurvey:
            // 转发B端显示聊前调查的请求
            this.installer.sendWidgetMsg(WidgetPageMessageType.WidgetPreviewShowChatSurvey, content);
            break;
        }
      }
    });
  }

  private addPostMessageListener(): void {
    window.addEventListener("message", (event: any): void => {
      const { data } = event;
      try {
        const msg = data as PostMessage;
        const { msgType } = msg || {};
        if (msgType) {
          this.installer.event.dispatch(eventName.POST_MESSAGE, msg);
        }
      } catch (err: any) {
        console.error(err);
      }
    });
  }

  private addDocumentListener(): void {
    document.addEventListener(
      BrowserUtils.getVisibilityChangeEventName(),
      () => {
        if (this.installer.kickouted) {
          this.installer.sendWidgetMsg(WidgetPageMessageType.ReinitializeIm);
        }
      },
      false
    );
    window.addEventListener(
      "resize",
      debounce(() => {
        // 浏览器窗口大小改变时重新渲染聊天框
        this.installer.setWidgetHeight();
        // 滚动聊天区域到底部
        this.installer.sendWidgetMsg(WidgetPageMessageType.MessageListScrollToEnd, null);
      }, 50)
    );
  }

  // 发送站点当前页面的信息
  private sendPageInfo(): void {
    const pageInfo = {
      pageTitile: document.title,
      visitTime: Date.now(),
      referUrl: document.referrer,
      url: location.href,
    };
    this.installer.sendWidgetMsg(WidgetPageMessageType.HostWebsiteInfo, pageInfo);
  }

  private pageChangeListener(): void {
    // <!---------- hash ---------->
    window.addEventListener("hashchange", this.sendPageInfo);

    // <!---------- history ---------->
    window.addEventListener("popstate", this.sendPageInfo);
    // 兼容history.pushState, replaceState
    // 第一阶段：我们对原生方法进行包装，调用前执行 dispatchEvent 了一个同样的事件
    function aop(type: string): () => void {
      const source = window.history[type];
      return function (...args): void {
        const event = new Event(type);
        // @ts-ignore
        event.arguments = args;
        window.dispatchEvent(event);
        // @ts-ignore
        const rewrite = source.apply(this, args);
        return rewrite;
      };
    }

    // 第二阶段：将 pushState 和 replaceState 进行基于 AOP 思想的代码注入
    window.history.pushState = aop("pushState");
    window.history.replaceState = aop("replaceState"); // 更改路由，不会留下历史记录

    // 第三阶段：捕获pushState 和 replaceState
    window.addEventListener("pushState", this.sendPageInfo, true);
    window.addEventListener("replaceState", this.sendPageInfo, true);
  }
}
