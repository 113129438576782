// 挂件宽度
export const widget_content_width = 380;
// 挂件高度
export const widget_content_height = 656;
export const widget_padding_inline = 32;
export const widget_padding_vertical = 52;
// 挂件iframe宽度
export const widget_iframe_width = 450;
// 挂件iframe高度
export const widget_iframe_height = widget_content_height + 12 + 12;

// 最小化时内容宽度/高度
export const widget_mini_content_height = 64;
export const widget_mini_content_width = 64;
export const widget_mini_padding_vertical = 12;
// 挂件iframe宽度
export const widget_mini_iframe_width = widget_mini_content_width;
// 挂件iframe高度
export const widget_mini_iframe_height = widget_mini_content_height;

// 最小化时内容宽度/高度
export const widget_mini_sider_content_height = 40;
export const widget_mini_sider_content_width = 40;
export const widget_mini_sider_padding_inline = 8;
export const widget_mini_sider_padding_vertical = 8;
export const widget_mini_sider_iframe_width = widget_mini_sider_content_width + 8 + 12;
// 挂件iframe高度
export const widget_mini_sider_iframe_height =
  widget_mini_sider_content_height + widget_mini_sider_padding_vertical * 2;
