// 该模块的主要是向外提供操作挂件的功能
import axios from "axios";
import { autobind } from "core-decorators";
import JsCookie from "js-cookie";

import { SHOPIFY_SOURCE } from "~/constants/source";

import { WidgetPageMessageType } from "../config";
import { eventName } from "../event/eventName";
import { IInstaller, InstallerCtl } from "../types";

declare const window: any;

@autobind
class Shopify extends InstallerCtl {
  static get allowSources(): number[] | undefined {
    return [SHOPIFY_SOURCE];
  }

  constructor(public readonly installer: IInstaller) {
    super(installer);
    this.addMessageListener();
  }

  // 获取购物车token
  public getCartToken(): string {
    return JsCookie.get("cart") as string;
  }

  // 获取用户id
  public getCustomerId(): string | undefined {
    return window.ShopifyCustomerId;
  }

  // 获取购物车数据
  public async getCart(): Promise<unknown> {
    try {
      const result = await axios({
        method: "get",
        url: `/cart.js`,
      });
      return result.data;
    } catch {
      return undefined;
    }
  }

  // to: 接收者的聊天账号
  private async sendShoppingInfo(to?: string): Promise<void> {
    let cart: any = [];
    try {
      cart = (await this.getCart()) || {};
    } catch (err: any) {
      console.error(err.toString());
    } finally {
      // 发送shopify的token、customerId到B端
      this.installer.sendWidgetMsg(WidgetPageMessageType.ShopifyShopInfo, {
        token: this.getCartToken(), // token
        customerId: this.getCustomerId(), // 客户id
        cart: {
          token: cart.token,
          note: cart.note,
          total_price: cart.total_price,
          total_discount: cart.total_discount,
          total_weight: cart.total_weight,
          item_count: cart.item_count,
          items: (cart.items || []).map((item: any) => ({
            id: item.id,
            quantity: item.quantity,
            price: item.price,
            product_id: item.product_id,
            product_title: item.product_title,
            image: item.image,
            url: item.url,
          })),
          currency: cart.currency,
          items_subtotal_price: cart.items_subtotal_price,
        }, // 购物车数据
        domain: location.hostname,
        to,
      });
    }
  }

  // 监听消息
  private addMessageListener(): void {
    this.installer.event.on(eventName.POST_MESSAGE, async (msg) => {
      try {
        const { msgType, content } = msg || {};
        switch (msgType) {
          case WidgetPageMessageType.PostFirstTrack: {
            this.sendShoppingInfo();
            break;
          }
          case WidgetPageMessageType.RequestShopifyShoppingInfo: {
            this.sendShoppingInfo(content as string);
          }
        }
      } catch (err: any) {
        console.error(err.toString());
      }
    });
  }
}

export default Shopify;
