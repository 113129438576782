export const BASE_PATH = "";

export const KEY_NAME_COUNTRY_CODE = "liveChat-countryCode";
export const KEY_NAME_COUNTRY_ID = "liveChat-countryId";
export const KEY_NAME_LANGUAGE_ID = "liveChat-languageId";
export const KEY_NAME_SOURCE = "liveChat-source";
export const KEY_NAME_CHAT_TARGET = "liveChat-chat-target";
export const KEY_NAME_WEBSITE_TYPE = "liveChat-website-type";
export const KEY_TOKEN = "lc-token";
export const KEY_RETURN_URL = "liveChat-return-url";
export const KEY_SHOP_ID = "liveChat-shop-id";
export const KAMICHAT_TOKEN = "kami-token";
// clientid前缀
export const KAMICHAT_WIDGET_CLIENT_ID_PREFIX = "KamiChat__client_id";
// 表示挂件是否打开过
export const KAMICHAT_WIDGET_OPENED = "KamiChat__widget_opened";
