import { PostMessage } from "~/common/types/postMessage";
import { getWidgetDomain } from "~/common/utils/config";

// export const KAMI_WIDGET_SCRIPT = "kami-script";
export const KAMI_WIDGET_SCRIPT = "kami-w"; // kami挂件script
export const KAMI_WIDGET_ID = "kima-widget-id"; // 挂件id
export const KAMI_WIDGET_WRAPPER = "kami-widget-wrapper";
export const KAMI_WIDGET = "kami-widget";
export const KAMI_WIDGET_MINI = "kami-widget-minimized"; // 最小化
export const KAMI_WIDGET_POPUP = "kami-widget-popup";
export const KAMI_WIDGET_POPOVER_MENU = "kami-widget-popover-menu";
export const KAMI_MSG_UNREAD_COUNT = "kami-msg-unread-count";
export const KAMI_NOTICE_TIPS = "kami-notice-tips";
export const KAMI_CHAT_BUTTON_LABTL = "kami-chat-button-label";
export const KAMI_NEW_MSG_LIST_ELE = "kami-new-msg-list-ele"; // 新消息展示列表

// 官网地址
export const getOfficalWebsiteAddress = (): string => {
  switch (process.env.BUILD_ENV) {
    case "prod":
      return "https://www.kamichat.im";
    case "test":
      return "https://test-www.kamichat.im";
    case "dev":
      return "https://dev-www.kamichat.im";
    default:
      return "https://www.kamichat.im";
  }
};

export const getCDNLink = (): string => {
  switch (process.env.BUILD_ENV) {
    case "prod":
      return "https://static.kamichat.im/biz-chat-plugin/";
    // return `https://${getDomain()}/`;
    case "test":
      return `https://${getWidgetDomain()}/`;
    case "dev":
      return `https://${getWidgetDomain()}/`;
    default:
      return `http://${getWidgetDomain()}/`;
  }
};

// 获取内容的连接
export const getContentLink = (): string => {
  switch (process.env.BUILD_ENV) {
    case "prod":
    case "test":
    case "dev":
      return `https://${getWidgetDomain()}/`;
    default:
      return `http://${getWidgetDomain()}/`;
  }
};
// 获取安装器的地址
export const getInstallerAddress = (): string => {
  return `${getCDNLink()}install.js`;
};

export const getMP3Address = (): string => {
  return getCDNLink() + "static/media/kami-notice-ring.mp3";
};

export enum WidgetPageMessageType {
  ViewImage = "view-image", // 预览图片
  ViewImageClose = "view-image-close", // 关闭预览图片
  WidgetInfo = "widget-info", // 插件信息
  WidgetViewMode = "wdigetViewMode", // 挂件的视图类型：open | default
  WidgetReady = "widget-ready", // 挂件已经初始化完成，请求编辑页发送数据
  WidgetKickout = "kick-out", // tim被踢出
  MsgUnreadCountChange = "msg-unread-count-change", // 消息未读数发生改变
  CanAlertMsg = "can-alert-msg", // 控制是否发送系统通知和发出通知提示音
  HostWebsiteInfo = "host-website-info", // 收集挂件所在页面的信息
  PostFirstTrack = "post-first-track", // 上报数据
  ReceiveTranslate = "receive-translate", // 接收翻译
  RequestShopifyShoppingInfo = "request-shopify-shopping-info", // 请求shopify 购物信息，推送给请求的用户
  ReceiveNewMSG = "receive-new-msg", // 接收到的新消息
  MessageListScrollToEnd = "message-list-scroll-to-end", // 消息列表滚动到底部
  ReinitializeIm = "kamichat-chat-reinitialize-im", // 重新出事化im
  NewMsgList = "kamichat-new-message-list", // 新消息列表

  // 提供第三方的消息类型
  OPENAPI_CHANGE_MODE = "openapi-change-mode", // 站点切换视图
  // shopify 商店 数据
  ShopifyShopInfo = "shopify-shop-info", // 发送shopify的token数据

  // <!----------  编辑预览消息 ------------>
  WidgetPreviewData = "widget-preview-data", // 编辑时预览的数据
  WidgetPreviewMode = "widget-preview-mode", // 编辑时模式的数据
  WidgetPreviewShowChatSurvey = "widget-preview-show-chat-survey", // 编辑时预览聊前调查效果
  WidgetPreviewCSInfo = "widget-preview-cs-info", // 编辑时客服数据
}

// 校验收到的post message消息是否正确,防止多个页面的数据干扰
export const validatePostMessage = (message: PostMessage, widgetId: string, instId: string): boolean => {
  return message.widgetId === widgetId && message.instId === instId;
};

export const getKamiWidgetCode = (): string[] => {
  const codeList: string[] = [
    //@kmia-widget-code-anchor
  ];
  return codeList;
};

export const getKamiWidgetMiniCssCode = (): string[] => {
  const codeList: string[] = [
    //@kmia-widget-mini-css-anchor
  ];
  return codeList;
};

export const getWidgetPostmessageAllowOrigin = (): string[] => {
  const origin: string[] = [
    // c
    "https://widget.kamichat.im",
    "https://test-widget.kamichat.im",
    "https://dev-widget.kamichat.im",
    // b
    "https://app.kamichat.im",
    "https://test-app.kamichat.im",
    "https://dev-app.kamichat.im",
  ];
  if (process.env.BUILD_ENV === "local") {
    origin.push("http://localhost:12000", "http://127.0.0.1:12000", "http://localhost:12001", "http://127.0.0.1:12001");
  }

  return origin;
};
