import { autobind } from "core-decorators";

import { WidgetViewMode } from "~/common/types/widget";

import { KAMI_MSG_UNREAD_COUNT, KAMI_NOTICE_TIPS, WidgetPageMessageType, getMP3Address } from "../config";
import { eventName } from "../event/eventName";
import { IInstaller, InstallerCtl } from "../types";
import BrowserUtils from "../browser";

declare const window: any;

// 提示模块
@autobind
class KamiChatNotification extends InstallerCtl {
  private _title?: string;

  private _timer?: NodeJS.Timeout;

  private _count = 0;

  private _canAlert = true;

  private audioElement?: HTMLMediaElement;

  constructor(public readonly installer: IInstaller) {
    super(installer);
    this.addDocumentListener();
    this.addMessageListener();
  }

  // audio
  private createAudioElement(): void {
    if (this.audioElement) {
      return;
    }
    const audioElement = document.createElement("audio");
    audioElement.hidden = true;
    audioElement.src = getMP3Address();
    this.audioElement = audioElement;
    if (this.audioElement) {
      const wrapper = this.installer.getWrapper();
      wrapper.appendChild(this.audioElement);
    }
  }

  private get title(): string {
    if (!this._title) {
      this._title = document.title;
    }
    return this._title;
  }

  private changeTitle(): void {
    if (!BrowserUtils.isHidden()) {
      return;
    }
    if (!this.installer.widgetInfo?.isOpenNewMessageTip) {
      return;
    }
    if (this._timer) {
      return;
    }
    this._timer = setInterval(() => {
      if (this._count % 2 === 0) {
        document.title = `【${this.installer.i18n["新消息"]}】` + this.title;
      } else {
        document.title = "\u200E"; // title设置为空
      }

      this._count++;
    }, 600);
  }

  private recoverTitle(): void {
    if (this._timer) {
      clearInterval(this._timer);
      this._timer = undefined;
      document.title = this.title;

      this._count = 0;
    }
  }

  // 监听消息
  private addMessageListener(): void {
    this.installer.event.on(eventName.POST_MESSAGE, async (msg) => {
      try {
        const { msgType, content } = msg || {};
        switch (msgType) {
          case WidgetPageMessageType.MsgUnreadCountChange: {
            const { count } = (content || {}) as { count: number };
            this.installer.setUnreadCount(count || 0);
            if (count) {
              this.changeTitle();
              this.audioPlay(count);
            } else {
              sessionStorage.setItem(KAMI_MSG_UNREAD_COUNT, "0");
            }
            break;
          }
          case WidgetPageMessageType.CanAlertMsg: {
            this._canAlert = content as boolean;
            break;
          }
          case WidgetPageMessageType.ReceiveNewMSG: {
            const { text, body } = (content || {}) as { text: string; body: string };
            this.notify(text, body);
            break;
          }
          case WidgetPageMessageType.WidgetInfo: {
            if (
              this.installer.isEdit &&
              this.installer.oldWidgetInfo &&
              !this.installer.oldWidgetInfo?.isOpenNewMessageSound &&
              this.installer.widgetInfo?.isOpenNewMessageSound
            ) {
              this.audioPlay(+new Date());
            }
            break;
          }
        }
      } catch (err: any) {
        console.error(err.toString());
      }
    });
  }

  // 声音控制
  private audioPlay(unreadCount: number): void {
    if (!this.installer.widgetInfo?.isOpenNewMessageSound) {
      return;
    }
    this.createAudioElement();
    const prevCount = sessionStorage.getItem(KAMI_MSG_UNREAD_COUNT) || 0;
    if (this.audioElement) {
      if (this._canAlert && unreadCount && Number(prevCount) !== unreadCount) {
        this.audioElement.pause();
        this.audioElement.load();
        const promise = this.audioElement.play();
        // chrome 有隐私保护政策，需要进入页面有点击等操作的时候，才会发出提示音
        // 开启了通知才允许发出声音。
        if (promise !== undefined) {
          promise.catch((err) => {
            // 方便查找原因
            sessionStorage.setItem(KAMI_NOTICE_TIPS, err.toString());
          });
        }
        if (promise !== undefined && !this._canAlert) {
          promise.then(() => {
            // 方便查找原因
            sessionStorage.setItem(KAMI_NOTICE_TIPS, "success");
          });
        }
      }
      sessionStorage.setItem(KAMI_MSG_UNREAD_COUNT, unreadCount + "");
    }
  }

  // 调用系统通知
  private notify(text: string, body: string): void {
    // 开启了通知才允许发出系统通知。
    if (!this._canAlert) return;
    if ("Notification" in window) {
      const createNotification = (): void => {
        const options = {
          body,
          icon: this.installer.widgetInfo?.logo,
        };
        const notification = new Notification(text, options);
        notification.onclick = (): void => {
          // 点击打开聊天框
          this.installer.sendWidgetMsg(WidgetPageMessageType.OPENAPI_CHANGE_MODE, WidgetViewMode.Open);
        };
      };
      if (Notification.permission === "granted") {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        // const notification = new Notification(text);
        createNotification();
      } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            // const notification = new Notification("Hi there!");
            createNotification();
          }
        });
      }
    }
  }

  private visibilityChange(): void {
    if (!BrowserUtils.isHidden()) {
      // 🈶页面切换为激活状态时停止闪烁
      this.recoverTitle();
    }
  }

  private addDocumentListener(): void {
    document.addEventListener(BrowserUtils.getVisibilityChangeEventName(), this.visibilityChange, false);
  }
}

export default KamiChatNotification;
