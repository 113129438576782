
        var result = require("!!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[12].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[12].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[12].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[12].use[4]!../../../node_modules/sass-resources-loader/lib/loader.js??ruleSet[1].rules[12].use[5]!./base.string.scss");

        if (result && result.__esModule) {
            result = result.default;
        }

        if (typeof result === "string") {
            module.exports = result;
        } else {
            module.exports = result.toString();
        }
    